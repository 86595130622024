import { buildRoutePaths } from './build-route-paths';

/** Web-view redirect URL. */
export const WEB_VIEW_REDIRECT_URL = 'redirect-from-web-view' as const;

/** Submission redirect to iOS. */
export const SUBMISSION_IOS_REDIRECT = 'ios-redirect' as const;

/** User ID parameter. */
export const USER_ID_PARAM = 'userId';

/** Session ID parameter. */
export const SESSION_ID_PARAM = 'sessionId';

/** Terms of service section param. */
export const TERMS_SECTION_PARAM = 'section';

/** Chat with user ID parameter. */
export const CHAT_WITH_USER_ID = 'chatWithUserId';

/** Gift details ID. */
export const GIFT_ID = 'giftId';

/*
	TODO(?): Get rid of common routes and forbid shared components to navigate users.
*/
const baseRoutePaths = buildRoutePaths({
	home: { path: '' },
	auth: {
		path: 'auth',
		children: {
			login: { path: 'login' },
			twoFactor: { path: 'two-factor' },
			twoFactorSetup: { path: 'two-factor-setup' },
			signUpIntro: { path: 'sign-up' },
			signUpProcess: { path: 'sign-up/process' },
			finish: { path: 'sign-up/finish' },
		},
	},
	restore: {
		path: 'restore',
		children: {
			confirmPassword: { path: 'confirm-password' },
			forgotPassword: { path: 'forgot-password' },
		},
	},
	portal: {
		path: 'portal',
		children: {
			tabs: {
				path: 'tabs',
				children: {
					home: { path: 'home' },
					chat: {
						path: 'chat',
						children: {
							session: {
								path: `session/:${SESSION_ID_PARAM}`,
								children: {
									progress: { path: 'progress' },
								},
							},

							/*
								I do not like that we have to put it there, in common routes,
								but it looks like the idea of common routes doesn't work at all.
								Ionic router, when we have tabs, requires to add different routes that lead to the same component.
								This is the only way to have a correct structure of non-linear routing.
							*/
							settings: { path: 'settings' },
						},
					},
					contacts: {
						path: 'contacts',
					},
					myProfile: {
						path: 'my-profile',
						children: {
							connect: { path: 'connect' },
							documents: { path: 'documents' },
						},
					},
					settings: {
						path: 'settings',
						children: {
							privacy: { path: 'privacy' },
							documents: { path: 'documents' },
							bankSettings: { path: 'bank-settings' },
							twoFactor: { path: 'two-factor' },
						},
					},
				},
			},
			inbox: { path: 'inbox' },

			earnings: {
				path: 'earnings',
				children: {
					gift: { path: `:${GIFT_ID}` },
				},
			},
			userProfile: {
				path: `users/:${USER_ID_PARAM}`,
				children: {
					requestConsultation: { path: 'request-consultation' },
				},
			},

			chatDetails: {
				path: `chat/:${CHAT_WITH_USER_ID}`,
				children: {
					gift: { path: 'gift' },
				},
			},

			pages: {
				path: 'pages',
				children: {
					request: {
						path: 'request',
						children: {
							selectUsersForRequest: { path: 'select-users' },
						},
					},
				},
			},
		},
	},
	policies: {
		path: '',
		children: {
			termsOfService: { path: 'terms-of-service' },
			privacyPolicy: { path: 'privacy-policy' },
		},
	},
} as const);

/** Common route paths can be used throughout the project. */
export const commonRoutePaths = {
	...baseRoutePaths,
};
